import React from 'react'
import './Navbar.css'
import logo from '../Assets/Color logo - no background.png'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';



const pages = ['Home', 'Services', 'Product','AboutUs','Contact'];
const pagesPath = {Home:'/', Services : '/services', Product : '/product',AboutUs : '/aboutus',Contact: '/contact'};
    


    function Navbar() {



      const [anchorElNav, setAnchorElNav] = React.useState(null);
    
      const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
      };
    
    
      const handleCloseNavMenu = () => {
        setAnchorElNav(null);
      };
    
    
      const darkTheme = createTheme({
        palette: {
          mode: 'dark',
          primary: {
            main: '#222831',
          },
        },
      });
      /*const frogTheme = createTheme({
        palette: {
          primary: {
            main: '#222831',
            light: '#37414f',
            dark: '#A29415',
            contrastText: '#59a52c',
          },
          secondary: {
            main: '#222831',
            light: '#37414f',
            dark: '#222831',
            contrastText: '#6fca3a',
          },
        },
      }); */
      return (
        <div>
          <ThemeProvider theme={darkTheme}>
        <AppBar position="static"
          color="primary" >
          <Container maxWidth="xl">
            <Toolbar 
              disableGutters>
            <span className="nav-logo"><img src={logo} alt="" /></span>
              
    
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }} justifyContent="flex-end" >
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  backgroundColor="#37414f"
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  {pages.map((page) => (
                    <MenuItem
                      key={page} 
                      onClick={handleCloseNavMenu}
                      backgroundColor="#37414f">
                      <Typography textAlign="center"
                                  sx={{ my: 2, color: '#59a52c', display: 'block' }}
                                  component={Link}
                                  to={pagesPath[page]}>{page}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} justifyContent="flex-end">
                {pages.map((page) => (
                  <Button
                    key={page}
                    onClick={handleCloseNavMenu}
                    sx={{ my: 2, color: '#59a52c', display: 'block' }}
                    className="scroll-to-section" 
                    component={Link}
                    to={pagesPath[page]}
                  >
                    {page}
                  </Button>
                ))}
              </Box>
    
              
            </Toolbar>
          </Container>
        </AppBar>
        </ThemeProvider>
 
        </div>
      );
    }
    export default Navbar;
