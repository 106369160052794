import React from 'react'
import homeImage from '../Components/Assets/MYOB-Advanced-financial-management.webp'
import myobImage from '../Components/Assets/MYOB_AdvancedBusinessPartner-2.png'
import devImage from '../Components/Assets/2.jpg'
import consultImage from '../Components/Assets/7.jpg'
import implImage from '../Components/Assets/3.jpg'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#59a52c"),
  backgroundColor: "#59a52c",
  '&:hover': {
    backgroundColor: "#6fca3a",
  },
}));

export const Home = () => {
  return (
    <Box sx={{ width: '100%',boxShadow: 0 }}>
      <Helmet>
        <title>Frog IT Services About Us</title>
        <meta name="description" content="This is the home of FROG IT Services team" />
        <meta name="keywords" content="ERP, Cloud ERP, MYOB, MYOB Advanced Business, cloud, ERP consulting,consulting, implemetation, integration,ERP services, enterprise resource planning,business management solutions, Manufacturing ERP,Distribution ERP, Retail ERP, Construction ERP, Financial ERP, Gold Coast ERP, Business Management Solutions, Supply Chain Management, Inventory Management, Financial Management, CRM (Customer Relationship Management), Business Intelligence (BI), Data Analytics, Queensland ERP Solutions, Sage   " />
        <meta name="author" content="Frog ITS" />
      </Helmet>
      <Stack spacing={2}      
          alignItems="center"
          justifyContent="center">
        <Item elevation={0}>
          <Card sx={{ maxWidth: 800 }}
                elevation={0}>
            <CardActionArea>
              
            <CardContent>
              <CardMedia
                component="img"
                //height="200"
                image={myobImage}
                alt="green iguana"
              />
                <Typography variant="body2" color="text.secondary">
                Cloud ERP software such as MYOB Advances provide a single solution that empowers your business and connects your team. Minimise repetitive tasks, deploy the foundation for automation and give time to invest in high valued tasks that increase overall business productivity and efficiency.
                </Typography>
              </CardContent>
              <CardMedia
                component="img"
                //height="200"
                image={homeImage}
                alt="green iguana"
              />
            </CardActionArea>
          </Card>
      </Item>
      <Item elevation={0}>
      <ColorButton variant="contained" component={Link} to='/contact'
      >Contact Us</ColorButton>
      </Item>
      <Item elevation={0}>
        <h1>What can we offer your business</h1>
      </Item>
      <Item elevation={0}>
       
              <Item>
              <Grid container spacing={2}  columns={{ xs: 4, sm: 8 }}>
              <Card sx={{ maxWidth: 400,boxShadow: 0 }}
              elevation={0}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  MYOB Advanced Consultants
                </Typography>
                <Typography variant="body2" color="text.secondary">
                Our implementation and support team consist of <strong>certified MYOB Advanced consultants</strong> who are among the most knowledgable consultants in Australia. Every business who implements a new ERP system in their organisation initially has many questions and require ongoing support. Our MYOB Advanced support team will provide you with MYOB Advanced ongoing support after the implementation.
                </Typography>
              </CardContent>
              
          </Card>
                <Card sx={{ maxWidth: 400,boxShadow: 0 }}
                elevation={0}>
              <CardMedia
                component="img"
                image={implImage}
                alt="green iguana"
              />
          </Card>
          </Grid>
          </Item>
          <Item>
          <br />
          </Item>
           
              <Item  elevation={0}>
              <Grid container spacing={2}  columns={{ xs: 4, sm: 8 }}>
                  <Item>
                <Box sx={{ maxWidth: 400,boxShadow: 0 }}
                elevation={0}>
                <CardMedia
                sx={{maxWidth: 400}}
                  component="img"
                  image={consultImage}
                  alt="green iguana"
                />
                </Box>

                  </Item>
                  <Item>
                <Box sx={{ maxWidth: 400,boxShadow: 0  }}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                Why Make the Shift?
                </Typography>
                <Typography variant="body2" color="text.secondary">
                In today's fast-paced digital landscape, businesses need more than just number crunching. They require strategic foresight, data-driven decision-making, and agile adaptability to stay ahead of the curve. Transitioning from conventional accounting to a comprehensive business platform equips you with the tools and insights necessary to thrive in this ever-evolving market.
                </Typography>
              </CardContent>
              

              </Box>

                  </Item>
               </Grid>
                
          </Item>
            <Item>
            <br />
            </Item>
              <Item  elevation={0}>
                
              <Grid container spacing={2}  columns={{ xs: 4, sm: 8 }}>
              <Card sx={{ maxWidth: 400,boxShadow: 0 }}
                elevation={0}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                What We Offer
                </Typography>
                <Typography variant="body2" color="text.secondary">
                <List sx={{ listStyleType: 'disc' }}>
                <ListItem sx={{ display: 'list-item' }}><strong>Cutting-edge Technology:</strong> Harness the power of advanced software solutions to streamline your processes, automate tedious tasks, and gain real-time visibility into your financial health.</ListItem>
                <ListItem sx={{ display: 'list-item' }}><strong>Strategic Consulting:</strong> Leverage our industry expertise to develop personalized strategies that drive growth, optimize performance, and maximize profitability.</ListItem>
                <ListItem sx={{ display: 'list-item' }}><strong>Continuous Support:</strong> Our commitment doesn't end after implementation. We provide ongoing support and training to empower you and your team to make the most of your new business platform.</ListItem>
                </List>
                </Typography>
              </CardContent>
          </Card>
                <Card sx={{ maxWidth: 400,boxShadow: 0 }}
                elevation={0}>
              
              <CardMedia
                component="img"
                image={devImage}
                alt="green iguana"
              />
              </Card>
          </Grid>
          </Item>
           
      </Item>
      <Item>

      </Item>
    </Stack>
    </Box>
  )
}
