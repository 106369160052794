import React from 'react'
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import adminImage from '../Components/Assets/6.jpg'
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Helmet } from 'react-helmet';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#59a52c"),
  backgroundColor: "#59a52c",
  '&:hover': {
    backgroundColor: "#6fca3a",
  },
}));

export const AboutUs = () => {
  return (
    <Stack spacing={2}      
    alignItems="center"
    justifyContent="center">
      <Helmet>
        <title>Frog IT Services About Us</title>
        <meta name="description" content="This page provides a discription of the FROG IT Services team" />
        <meta name="keywords" content="ERP, Cloud ERP, MYOB, MYOB Advanced Business, cloud, ERP consulting,consulting, implemetation, integration,ERP services, enterprise resource planning,business management solutions, Manufacturing ERP,Distribution ERP, Retail ERP, Construction ERP, Financial ERP, Gold Coast ERP, Business Management Solutions, Supply Chain Management, Inventory Management, Financial Management, CRM (Customer Relationship Management), Business Intelligence (BI), Data Analytics, Queensland ERP Solutions, Sage   " />
        <meta name="author" content="Frog ITS" />
      </Helmet>
      <Item elevation={0}></Item>
    <Item elevation={0}>
    <Grid container spacing={2}  columns={{ xs: 4, sm: 8 }}>
      <Card  sx={{ maxWidth: 400 }}
                >
                    
              <CardMedia
                component="img"
                image={adminImage}
                alt="green iguana"
              />
              </Card>
              <Card  sx={{ maxWidth: 400 }}
                >
                  <CardContent sx={{ flex: '1 0 auto' }}>
                      <Typography gutterBottom variant="h5" component="div">
                      About FROG ITS
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                      Welcome to FROG ITS, your trusted partner in ERP consulting and implementation. With a passion for helping businesses thrive in today's competitive landscape, we specialize in providing tailored solutions that drive efficiency, innovation, and growth.
                      </Typography>
                    </CardContent>
                 
          </Card>
          </Grid>
  </Item>
  <Item>
    <Paper sx={{maxWidth:800}}>
      <Typography gutterBottom variant="h5" component="div">
      Our Mission
      </Typography>
      <Typography variant="body2" color="text.secondary">
      At FROG ITS, our mission is simple: to empower businesses of all sizes to unlock their full potential through the strategic implementation of ERP solutions. We believe that every organization deserves access to the tools and insights necessary to succeed in today's fast-paced digital world, and we are committed to delivering exceptional value and service to each and every one of our clients.
                </Typography>
                <br />
                <Typography gutterBottom variant="h5" component="div">
                Why Choose Us?
                </Typography>
                <CardContent sx={{ flex: '1 0 auto' }}>
                <Typography variant="body2" color="text.secondary">
                <List sx={{ listStyleType: 'disc' }}>
                  <ListItem sx={{ display: 'list-item' }}><strong>Expertise:</strong> Our team of seasoned ERP consultants brings years of industry experience and expertise to the table. From initial assessment to post-implementation support, we have the knowledge and skills necessary to guide you through every step of the ERP journey.</ListItem>
                  <ListItem sx={{ display: 'list-item' }}><strong>Tailored Solutions:</strong> We understand that every business is unique. That's why we take the time to listen to your needs, goals, and challenges before developing customized ERP solutions that are perfectly aligned with your organization's objectives.</ListItem>
                  <ListItem sx={{ display: 'list-item' }}><strong>Commitment to Excellence: </strong> We are dedicated to delivering the highest quality service and support to our clients. From transparent communication to proactive problem-solving, we go above and beyond to ensure your satisfaction and success.</ListItem>
                </List>
                </Typography>
                </CardContent>

    </Paper>
  </Item>
  <Item elevation={0}>
  <Paper sx={{maxWidth:800}}>
  <Typography gutterBottom variant="h5" component="div">
  Get in Touch
                </Typography>
  <Typography variant="body2" color="text.secondary">Ready to take your business to the next level with ERP? Contact us today to schedule a consultation and discover how FROG ITS can help you achieve your goals.</Typography>
  <br />
    <ColorButton variant="contained" component={Link} to='/contact'
      >Contact Us</ColorButton>
      <br /> <br />
      </Paper>
      </Item>
  </Stack>
  )
}
