import React, { Component } from 'react'
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import logo from '../Assets/Color logo - no background.png'
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#222831',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const pages = ['Home', 'Services', 'Product','AboutUs','Contact'];
  const pagesPath = {Home:'/', Services : '/services', Product : '/product',AboutUs : '/aboutus',Contact: '/contact'};

export class Footer extends Component {
  render() {
    return (
        <Stack spacing={2}>
        <Item>
            <Grid container spacing={2}  columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={4}>
                    <Item>
                    <span className="nav-logo"><img src={logo} alt="" /></span>
                    </Item>
                </Grid>
                <Grid item xs={4}>
                    <Item>
                        
                    </Item>
                </Grid>
                <Grid item xs={4}>
                    <Item>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} justifyContent="flex-end">
                            {pages.map((page) => (
                            <Button
                                key={page}
                                sx={{ my: 2, color: '#59a52c', display: 'block' }}
                                className="scroll-to-section" 
                                component={Link}
                                to={pagesPath[page]}
                            >
                                {page}
                            </Button>
                            ))}
                        </Box>
                    </Item>
                </Grid>
            </Grid>


        </Item>
      </Stack>
    )
  }
}

export default Footer