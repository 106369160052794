import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { FormControl } from '@mui/base/FormControl';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const ColorTextField = styled(TextField)(({ theme }) => ({
  color: theme.palette.getContrastText("#59a52c"),
  '&:hover': {
    BorderColor: "#6fca3a",
  },
  '& label.Mui-focused': {
    color: "#6fca3a",
  },
  // focused color for input with variant='standard'
  "& .MuiInput-underline:after": {
    borderBottomColor: "#6fca3a"
  },
  // focused color for input with variant='filled'
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: "#6fca3a"
  },
  // focused color for input with variant='outlined'
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: "#6fca3a"
    }
  }
}));


const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#59a52c"),
  backgroundColor: "#59a52c",
  '&:hover': {
    backgroundColor: "#6fca3a",
  },
}));



export default function ContactUs() {

    const form = useRef();
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")


    const [open, setOpen] = React.useState(false);
    
      const handleClickOpen = () => {
        setOpen(true);
      };
      const handleClose = () => {
        setOpen(false);
      };


    const sendEmail = (e) => {
      e.preventDefault();
      var templateParams = {
        from_name: name,
        from_email: email,
        message: message,
      };

      emailjs.init({
        publicKey: 'W4Xkv4J6tdfb2XZHl',
        // Do not allow headless browsers
        blockHeadless: true,
        blockList: {
          // Block the suspended emails
          list: ['foo@emailjs.com', 'bar@emailjs.com'],
          // The variable contains the email address
          watchVariable: email,
        },
        limitRate: {
          // Set the limit rate for the application
          id: 'app',
          // Allow 1 request per 10s
          throttle: 10000,
        },
      });

      emailjs
        .send('service_j35h2lc', 'template_d7oub53', templateParams)
        .then(
          () => {
            console.log('SUCCESS!');
          },
          (error) => {
            console.log('FAILED...', error.text);
          },
        );
        handleClickOpen();
        setName("");
        setEmail("");
        setMessage("");

    };
  return (
    <form ref={form} onSubmit={sendEmail}>
      <FormControl>
      <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: 400 },
      }}
      autoComplete="off"
    >
      <ColorTextField 
            type="text" 
            label="Name" 
            onChange={e => setName(e.target.value)}
            focused name='user_name'
            value={name} />
    </Box>
      <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: 400 },
      }}
      autoComplete="off"
    >
      <ColorTextField 
            type="email" 
            label="Email"            
            onChange={e => setEmail(e.target.value)}
            focused name='user_email' 
            value={email}
            />
    </Box>
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: 400 },
      }}
      autoComplete="off"
    >
      <ColorTextField
          label="Message"
          multiline
          rows={6}
          name="message"
          onChange={e => setMessage(e.target.value)}
          value={message}
        />
        </Box>
      <ColorButton variant="contained" type="submit" >Contact</ColorButton>
      </FormControl>
      <React.Fragment>
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              FrogITS
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <Typography gutterBottom>
                Thank you for contacting FrogITS. One of our team memebers will contact you as soon as possible.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose}>
                Ok
              </Button>
            </DialogActions>
          </BootstrapDialog>
        </React.Fragment>
    </form>
  )
}