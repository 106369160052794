import React from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import homeImage from '../Components/Assets/MYOB_AdvancedBusinessPartner-2.png'
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import consultImage from '../Components/Assets/7.jpg'
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import desImage from '../Components/Assets/4.jpg'
import devImage from '../Components/Assets/6t2v76bn.jpg'
import adminImage from '../Components/Assets/6.jpg'
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#59a52c"),
  backgroundColor: "#59a52c",
  '&:hover': {
    backgroundColor: "#6fca3a",
  },
}));

export const Product = () => {
  return (
    <Box sx={{ width: '100%',boxShadow: 0 }}>
      <Stack spacing={2}      
          alignItems="center"
          justifyContent="center">
            <Helmet>
        <title>Frog IT Services About Us</title>
        <meta name="description" content="This page provides a discription of the FROG IT Services products provided" />
        <meta name="keywords" content="ERP, Cloud ERP, MYOB, MYOB Advanced Business, cloud, ERP consulting,consulting, implemetation, integration,ERP services, enterprise resource planning,business management solutions, Manufacturing ERP,Distribution ERP, Retail ERP, Construction ERP, Financial ERP, Gold Coast ERP, Business Management Solutions, Supply Chain Management, Inventory Management, Financial Management, CRM (Customer Relationship Management), Business Intelligence (BI), Data Analytics, Queensland ERP Solutions, Sage   " />
        <meta name="author" content="Frog ITS" />
      </Helmet>
        <Item elevation={0}>
          <Card sx={{ maxWidth: 800 }}
                elevation={0}>
            <CardActionArea>
              <CardMedia
                component="img"
                image={homeImage}
                alt="green iguana"
              />
              
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                The cloud-based ERP solution that scales with your business
                </Typography>
                <Typography variant="body2" color="text.secondary">
                MYOB Advanced Business is a customisable cloud Enterprise Resource Planning system that gives you complete real-time visibility and control of your business.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
      </Item>
      <Item  elevation={0}><br /></Item>
      <Item   sx={{ maxWidth: 830 }}><Grid container spacing={2}  columns={{ xs: 4, sm: 8 }}>
  <Grid item xs={4}>
      <Item  sx={{ maxWidth: 400 }}>
      <Card  sx={{ maxWidth: 400 }}
                elevation={0}>
                  <CardActionArea>
                  
                    
                  <CardContent >
                      <Typography gutterBottom variant="h5" component="div">
                      An all-in-one platform
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                      Manage your financials, customers, projects and reporting, all from one integrated system.
                      </Typography>
                    </CardContent>
              <CardMedia
                component="img"
                image={consultImage}
                alt="green iguana"
              />
                    </CardActionArea>
          </Card>
      </Item>
  </Grid>
  <Grid item xs={4}>
      <Item  sx={{ maxWidth: 400 }}>
      <Card  sx={{ maxWidth: 400 }}
                elevation={0}>
                <CardActionArea>
              <CardMedia
                component="img"
                image={devImage}
                alt="green iguana"
              />
                  
                    
                  <CardContent sx={{ flex: '1 0 auto' }}>
                      <Typography gutterBottom variant="h5" component="div">
                      Available when you are
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                      Now you can access critical information anywhere, any time on any device.
                      </Typography>
                    </CardContent>
                    </CardActionArea>
          </Card>
      </Item>
  </Grid>
  <Grid item xs={4}>
      <Item  sx={{ maxWidth: 400 }}>
      <Card  sx={{ maxWidth: 400 }}
                elevation={0}>
              <CardActionArea>
              <CardMedia
                component="img"
                image={desImage}
                alt="green iguana"
              />
                  
                    
                  <CardContent sx={{ flex: '1 0 auto' }}>
                      <Typography gutterBottom variant="h5" component="div">
                      Data to power your decisions
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                      Gain a 360-degree view of your business to make insight-driven decisions.
                      </Typography>
                    </CardContent>
                    </CardActionArea>
          </Card>
      </Item>
  </Grid>
  <Grid item xs={4}>
  <Item  sx={{ maxWidth: 400 }}>
      <Card  sx={{ maxWidth: 400 }}
                elevation={0}>
                  
                  <CardActionArea> 
                  <CardContent sx={{ flex: '1 0 auto' }}>
                      <Typography gutterBottom variant="h5" component="div">
                      Say goodbye to tedious admin
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                      Automate manual processes and improve productivity across your company.
                      </Typography>
                    </CardContent>
              <CardMedia
                component="img"
                image={adminImage}
                alt="green iguana"
              />
                    </CardActionArea>   
          </Card>
      </Item>
  </Grid>
</Grid></Item>
      <Item elevation={0}>
      <ColorButton variant="contained" component={Link} to='/contact'
      >Contact Us</ColorButton>
      </Item>
    </Stack>
    </Box>
  )
}
