import React from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import consultImage from '../Components/Assets/7.jpg'
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import projImage from '../Components/Assets/project-management.jpg'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#59a52c"),
  backgroundColor: "#59a52c",
  '&:hover': {
    backgroundColor: "#6fca3a",
  },
}));

export const Services = () => {
  return (
    <Box sx={{ flexGrow: 1, width: '100%',boxShadow: 0  }}>
      <Helmet>
        <title>Frog IT Services About Us</title>
        <meta name="description" content="This page provides a discription of the FROG IT Services services provided" />
        <meta name="keywords" content="ERP, Cloud ERP, MYOB, MYOB Advanced Business, cloud, ERP consulting,consulting, implemetation, integration,ERP services, enterprise resource planning,business management solutions, Manufacturing ERP,Distribution ERP, Retail ERP, Construction ERP, Financial ERP, Gold Coast ERP, Business Management Solutions, Supply Chain Management, Inventory Management, Financial Management, CRM (Customer Relationship Management), Business Intelligence (BI), Data Analytics, Queensland ERP Solutions, Sage   " />
        <meta name="author" content="Frog ITS" />
      </Helmet>
      <Stack spacing={2}      
          alignItems="center"
          justifyContent="center">
            <Item sx={{display: 'none'}} elevation={0}><br /></Item>
            <Item elevation={0}>
            <Grid container spacing={2}  columns={{ xs: 4, sm: 8 }}>
          <Card sx={{ maxWidth: 400 }}
                elevation={0}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                Why Choose Frog ITS ERP Services?
                </Typography>
                <Typography variant="body2" color="text.secondary">
                <List sx={{ listStyleType: 'disc' }}>
                <ListItem sx={{ display: 'list-item' }}><strong>Customized Solutions:</strong> We understand that every business is different. That's why we work closely with you to develop customized ERP solutions that align perfectly with your goals, processes, and industry requirements.</ListItem>

                <ListItem sx={{ display: 'list-item' }}><strong>End-to-End Implementation:</strong> From initial assessment and planning to configuration, deployment, and training, our experienced team manages the entire ERP implementation process, ensuring a seamless transition and minimal disruption to your operations.</ListItem>

                <ListItem sx={{ display: 'list-item' }}><strong>Continuous Support:</strong> Our commitment to your success doesn't end after implementation. We provide ongoing support, maintenance, and updates to keep your ERP system running smoothly and efficiently, allowing you to focus on driving growth and innovation.</ListItem>
                </List>
                </Typography>
              </CardContent>
              </Card> 
           <Card sx={{ maxWidth: 400 }}
           elevation={0}>  
              <CardMedia
                component="img"
                image={projImage}
                alt="green iguana"
              />
          </Card>
          </Grid>
      </Item>
      <Item sx={{display: 'none'}} elevation={0}><br /></Item>
      <Item>
      <Grid container spacing={2}  columns={{ xs: 4, sm: 8 }}>
      <Card sx={{ maxWidth: 400 } }
                elevation={0}>
                  
              <CardMedia
                component="img"
                image={consultImage}
                alt="green iguana"
              />

              </Card>
              <Card sx={{ maxWidth: 400 }}
                elevation={0}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                Our ERP Services
                </Typography>
                <Typography variant="body2" color="text.secondary">
                <List sx={{ listStyleType: 'disc' }}>
                <ListItem sx={{ display: 'list-item' }}><strong>ERP Consulting:</strong> Gain insights and recommendations from our seasoned consultants to determine the best ERP solution for your business and optimize your existing processes for maximum efficiency.</ListItem>

                <ListItem sx={{ display: 'list-item' }}><strong>ERP Implementation:</strong> Let our experts handle the technical aspects of ERP implementation, including software installation, data migration, customization, and integration with third-party systems.</ListItem>

                <ListItem sx={{ display: 'list-item' }}><strong>ERP Training:</strong> Empower your team with comprehensive training programs designed to ensure they have the knowledge and skills necessary to leverage the full capabilities of your ERP system.</ListItem>
                </List>
                </Typography>
              </CardContent>
              
          </Card>
          </Grid>
      </Item>
      <Item elevation={0}>
      <Typography gutterBottom variant="h5" component="div">
      Ready to Transform Your Business with ERP?
                </Typography>
                <Typography maxWidth={800}>
                Take the first step towards a more streamlined, efficient, and agile organization with [Your Company Name] ERP Services. Contact us today to schedule a consultation and discover how our tailored solutions can drive your business forward.
                </Typography>
                <br />
      <ColorButton variant="contained" component={Link} to='/contact'
      >Contact Us</ColorButton>
      </Item>
      
        </Stack>
    </Box>
  )
}
