import React from 'react';
import './App.css';
import  Navbar  from './Components/Navbar/Navbar';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import {Home} from './Pages/Home';
import { Services } from './Pages/Services';
import { Product } from './Pages/Product';
import { AboutUs } from './Pages/AboutUs';
import { Contact } from './Pages/Contact';
import Footer from './Components/Footer/Footer';

function App() {
  return (
    <div >
      <BrowserRouter>
        <Navbar/>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='services' element={<Services/>}/>
          <Route path='product' element={<Product/>}/>
          <Route path='aboutus' element={<AboutUs/>}/>
          <Route path='contact' element={<Contact/>}/>
        </Routes>
      <Footer />
      </BrowserRouter>
      

  
    </div>
  );
}

export default App;
