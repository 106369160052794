import React from 'react'
import ContactUs from '../Components/ContactUs/ContactUs';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export const Contact = () => {
  return (
    <Box  display="flex" m="auto"
    alignItems="center"
    justifyContent="center">
      <Helmet>
        <title>Frog IT Services About Us</title>
        <meta name="description" content="This page provides a way to contact the FROG IT Services team" />
        <meta name="keywords" content="ERP, Cloud ERP, MYOB, MYOB Advanced Business, cloud, ERP consulting,consulting, implemetation, integration,ERP services, enterprise resource planning,business management solutions, Manufacturing ERP,Distribution ERP, Retail ERP, Construction ERP, Financial ERP, Gold Coast ERP, Business Management Solutions, Supply Chain Management, Inventory Management, Financial Management, CRM (Customer Relationship Management), Business Intelligence (BI), Data Analytics, Queensland ERP Solutions, Sage   " />
        <meta name="author" content="Frog ITS" />
      </Helmet>
      <Stack spacing={2}      
          alignItems="center"
          justifyContent="center"
          sx={{ maxWidth:420}}>
            <Item elevation={0}>
                <Typography>
                  We would be honored to assist your business in selecting the right solution. We would like to share the journey of growing your business. Please let us know how we can assist your business.
                </Typography>
            </Item>
            <Item elevation={0}>
                <ContactUs />
            </Item>
      </Stack>
    </Box>    
  )
}

